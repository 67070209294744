import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
import progressiveVueImage from '../images/progressive-vue.png';
import vueBlockImage from '../images/vue-block-tree.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Design Principles of Vue 3.0 by Evan You`}</h1>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <iframe width="560" height="315" src="https://www.youtube.com/embed/WLpLYhnGqPA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{
        "margin": "1rem auto"
      }}></iframe>
    </div>
    <h2><HashLink id="goals" to="/vue-3-talk#goals" mdxType="HashLink">{`VueJS optimizes for approachability and scalability`}</HashLink></h2>
    <p>{`Folks from all different backgrounds decide to learn Vue.`}</p>
    <ul>
      <li parentName="ul">{`Beginners just progressing from HTML & CSS`}</li>
      <li parentName="ul">{`Professionals moving on from jQuery`}</li>
      <li parentName="ul">{`Veterans migrating from another framework`}</li>
      <li parentName="ul">{`Backend engineers looking for a lightweight frontend solution`}</li>
      <li parentName="ul">{`Architects choosing the foundation for an entire organization`}</li>
    </ul>
    <p>{`Vue is scalable and supports a variety of use cases.`}</p>
    <ul>
      <li parentName="ul">{`Sprinkling interactivity onto legacy applications`}</li>
      <li parentName="ul">{`One-off projects which demand fast turn-around but no long-term maintenance concerns`}</li>
      <li parentName="ul">{`Medium scale apps where complexity cap is predictable`}</li>
      <li parentName="ul">{`Large scale projects expected to last for years with team churn`}</li>
    </ul>
    <h2><HashLink id="library-vs-framework" to="/vue-3-talk#library-vs-framework" mdxType="HashLink">{`Library (e.g. React) vs Framework (e.g. Angular)`}</HashLink></h2>
    <p>{`Small library pros:`}</p>
    <ul>
      <li parentName="ul">{`Fewer concepts to get started with`}</li>
      <li parentName="ul">{`More flexibility and more active ecosystem`}</li>
      <li parentName="ul">{`Smaller maintenance surface so the team can focus on exploring new ideas`}</li>
    </ul>
    <p>{`Small library cons:`}</p>
    <ul>
      <li parentName="ul">{`More plumbing work needed by the developer`}</li>
      <li parentName="ul">{`Patterns emerge over time and become semi-required knowledge without really being documented`}</li>
      <li parentName="ul">{`Fast moving ecosystem can lead to fragmentation`}</li>
    </ul>
    <p>{`Large framework pros:`}</p>
    <ul>
      <li parentName="ul">{`Most of the common problems can be solved with a built-in abstractions`}</li>
      <li parentName="ul">{`Centralized design process ensures consistent and coherent ecosystem`}</li>
    </ul>
    <p>{`Large framework cons:`}</p>
    <ul>
      <li parentName="ul">{`Higher barrier to entry`}</li>
      <li parentName="ul">{`Inflexible if built-in solution doesn’t fit use case`}</li>
      <li parentName="ul">{`Larger maintenance surface makes introducing new ideas more costly`}</li>
    </ul>
    <p>{`Vue tries to strike a balance between library and framework. Vue has a layered design that allows for features to be opted into in a progressive manner.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={progressiveVueImage} alt="The layers of vue progressive learning. Declarative rendering, component system, client-side routing, large scale state management, build system, client/server data persistance" width="90%" />
    </div>
    <p>{`This gives the developer a low barrier to entry along with documented solutions for common problems.`}</p>
    <h2><HashLink id="features" to="/vue-3-talk#features" mdxType="HashLink">{`Vue 3 features`}</HashLink></h2>
    <p>{`The composition API is a purely additive feature of Vue 3 that provides a facility for us to better organize our code, reuse our code and leverage types in our code.`}</p>
    <p>{`Vue 3 is more performant than Vue 2 and many other JS frameworks. Virtual DOM processing is efficiently accomplished via the “block tree” algorithm.`}</p>
    <div style={{
      "display": "flex",
      "justifyContent": "center"
    }}>
  <img src={vueBlockImage} alt="The template is divided into blocks based on structure. Within each block the node structure is fully static. Each block only needs a single flat array to track dynamic nodes." width="90%" />
    </div>
    <p>{`Vue 3 is much smaller in size than Vue 2. Most global APIs and internal helpers are tree-shakable ES module exports. Also, the compiler generates tree-shake-friendly code.`}</p>
    <p>{`Vue 3 also comes with first-class custom renderer APIs as well as first-class custom compiler APIs to allow for even more low-level flexibility. Custom renderer APIs will make things like targeting different rendered outputs (mobile, terminal, etc.) much easier. Custom compiler APIs will make things like custom compile-time transformations (compile-time internationalization, compile-time accessibility, etc.) much easier.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      